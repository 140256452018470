const initialState = {
  connected: false,
  address: null,
  userInfo: null,
  userStats: null,
  show: false,
  refByAddress: "",
  signers: null,
  allUsers: null,
  poolCounts: [],
  referralAddrs: "",
  contractInstance: null,
  web3:null
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CONNECTED":
      return {
        ...state,
        connected: action.payload,
      };
    case "SET_ADDRESS":
      return {
        ...state,
        address: action.payload,
      };
    case "SET_USER_INFO":
      return {
        ...state,
        userInfo: action.payload,
      };
    case "SET_USER_STATS":
      return {
        ...state,
        userStats: action.payload,
      };
    case "SET_SHOW":
      return {
        ...state,
        show: action.payload,
      };
    case "SET_REF_ADDRESS":
      return {
        ...state,
        refByAddress: action.payload,
      };
    case "SET_SIGNER":
      return {
        ...state,
        signers: action.payload,
      };
    case "SET_ALL_USERS":
      return {
        ...state,
        allUsers: action.payload,
      };
    case "SET_POOL_COUNTS":
      return {
        ...state,
        poolCounts: action.payload,
      };
    case "SET_REFERRAL_ADDRESS":
      return {
        ...state,
        referralAddrs: action.payload,
      };
    case "SET_CONTRACT_INSTANCE":
      return {
        ...state,
        contractInstance: action.payload,
      };
    case "SET_WEB3":
      return {
        ...state,
        web3: action.payload,
      };
    default:
      return state;
  }
};

export default rootReducer;
