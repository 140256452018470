import { React, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import Autopool from "./Autopool";
// import { toast } from "react-toastify";
// import { Navigate } from "react-router-dom";
const Dashboard = () => {
  const { connected, address, userInfo, userStats, allUsers } = useSelector(
    (state) => state
  );
  // const navigate = useNavigate();

  // const addressToCheck = "0x0000000000000000000000000000000000000000";


  // if (userInfo && userInfo.userAddress === addressToCheck || !userInfo) {
  //     return <Navigate to="/" />;
  // }
  // console.log(userStats);


  const hostURL = window.location.origin;
  const referralLinkRef = useRef(null);

  let directPlusLevelEarning = 0;
  let autopoolReferralEarning = 0;
  let autopoolEarning = 0;
  let directTeam = 0;
  let allUsersCount = 0;

  if (connected && userStats) {
    directPlusLevelEarning =
      (Number(userStats.directReferralEarning) +
        Number(userStats.levelEarning)) /
      10 ** 18;
    autopoolReferralEarning =
      Number(userStats.autopoolReferralEarning) / 10 ** 18;
    autopoolEarning = Number(userStats.autopoolEarning) / 10 ** 18;
  }

  // console.log(allUsers)

  if (connected && userInfo) {
    directTeam = userInfo.referralsCount;
  }

  if (connected && allUsers) {
    allUsersCount = allUsers.length;
  }

  function countChildUsers(referralData, userAddress, level = 1) {
    if (level > 10) {
      return 0; // Reached the maximum level, stop counting
    }
    let childCount = 0;
    for (let i = 0; i < referralData.length; i++) {
      const [referrer, address] = referralData[i];
      if (referrer.toLowerCase() === userAddress.toLowerCase()) {
        childCount++; // Found a child user
        childCount += countChildUsers(referralData, address, level + 1);
      }
    }
    return childCount;
  }

  let childCount = 0;
  if (connected && address && allUsers) {
    childCount = countChildUsers(allUsers, address);
  }
  let refLink = "No Referral Link";
  if (connected && address) {
    refLink = `${hostURL}?upline_address=${address}`;
  }
  let globalUsers = 0;

  if (allUsers) {
    // console.log(allUsers);
    const filteredallUserGlobalPoolOne = allUsers.filter(
      (obj) => obj.poolIsjoined
    );
    
    const filteredallUserGlobalPoolBooster = allUsers.filter(
      (obj) => obj.poolBoosterIsjoined
    );
    const filteredallUserGlobalPoolSuperBooster = allUsers.filter(
      (obj) => obj.poolSuperBoosterIsjoined
    );
    globalUsers =
      filteredallUserGlobalPoolOne.length +
      filteredallUserGlobalPoolBooster.length +
      filteredallUserGlobalPoolSuperBooster.length; 
  }

  

  const copyReferralLink = () => {
    referralLinkRef.current.select();
    document.execCommand("copy");
    window.getSelection().removeAllRanges();

    // Optional: Display a message to indicate successful copying
    // alert("Referral link copied to clipboard!");
    toast.success("Referral link copied!");
  };

  return (
    <>
      <div className="gamfi-breadcrumbs-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="breadcrumbs-area sec-heading">
                <h3 className="title mb-0 mt-4">Dashboard</h3>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="breadcrumbs-area sec-heading">
                <h4 className="title mb-0 mt-4">Referral Link</h4>
                <div className="referral-link">
                  <div className="d-flex align-items-center">
                    <input
                      className="mb-2 form-control"
                      ref={referralLinkRef}
                      type="text"
                      value={refLink}
                      readOnly
                      style={{ background: "transparent", color: "white" }}
                    />
                    &nbsp;&nbsp;
                    <div style={{ color: "#a3ff12" }}>
                      <i
                        className="fas fa-copy fa-lg mr-2"
                        onClick={copyReferralLink}
                        title="Copy"
                        style={{
                          cursor: "pointer",
                          fontSize: 20,
                          color: "#a3ff12",
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="participat-information project-details-conent gamfi-about-secion pb-25 md-pb-50">
        <div className="container" style={{ maxWidth: "1000px" }}>
          <div className="row pt-40">
            <div className="col-lg-4">
              <div className="project-item project-value-inner d-flex justify-content-between align-items-center mb-30">
                <div className="project-value">
                  <h4 className="mb-2">Registration</h4>
                  <h5 className="mb-2">Direct + Level Earning</h5>
                  {/* <br /> */}
                  <span>
                    <h4 style={{ color: "#f3ba2f", marginBottom: "-20px" }}>
                      {directPlusLevelEarning} MATIC
                    </h4>
                  </span>
                </div>
                <div className="project-value-image">
                  <img
                    className="heading-right-image"
                    src="assets/images/MaterialIcons/user.png"
                    alt="referral_income"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 pl-25 md-pl-15">
              <div className="project-item project-value-inner d-flex justify-content-between align-items-center mb-30">
                <div className="project-value">
                  <h4 className="mb-2">Autopool</h4>
                  <h5 className="mb-2">Referral Earning</h5>
                  {/* <br /> */}
                  <span>
                    <h4 style={{ color: "#f3ba2f", marginBottom: "-20px" }}>
                      {autopoolReferralEarning} <b>MATIC</b>
                    </h4>
                  </span>
                </div>
                <div className="project-value-image">
                  <img
                    className="heading-right-image"
                    src="assets/images/MaterialIcons/referral_income.png"
                    alt="referral_income"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 pl-25 md-pl-15">
              <div className="project-item project-value-inner d-flex justify-content-between align-items-center mb-30">
                <div className="project-value">
                  <h4 className="mb-2">Autopool</h4>
                  <h5 className="mb-2">Earning</h5>
                  {/* <br /> */}
                  <span>
                    <h4 style={{ color: "#f3ba2f", marginBottom: "-20px" }}>
                      {autopoolEarning} <b>MATIC</b>
                    </h4>
                  </span>
                </div>
                <div className="project-value-image">
                  <img
                    className="heading-right-image"
                    src="assets/images/MaterialIcons/autopool_eaning.png"
                    alt="rank"
                    style={{
                      height: 45,
                      width: 45,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="project-item project-value-inner d-flex justify-content-between align-items-center mb-30">
                <div className="project-value">
                  <h4 className="mb-2">Direct Team</h4>
                  <span className="mb-2">
                    <h4 style={{ color: "#f3ba2f" }}>{directTeam}</h4>
                  </span>
                </div>
                <div className="project-value-image">
                  <img
                    className="heading-right-image"
                    src="assets/images/MaterialIcons/direct_team.png"
                    alt="rank"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 pl-25 md-pl-15">
              <div className="project-item project-value-inner d-flex justify-content-between align-items-center mb-30">
                <div className="project-value">
                  <h4 className="mb-2">Level 2 to 10 Team</h4>
                  <span className="mb-2">
                    <h4 style={{ color: "#f3ba2f" }}>
                      {childCount - directTeam}
                    </h4>
                  </span>
                </div>
                <div className="project-value-image">
                  <img
                    className="heading-right-image"
                    src="assets/images/MaterialIcons/levelone_toten.png"
                    alt="rank"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 pl-25 md-pl-15">
              <div className="project-item project-value-inner d-flex justify-content-between align-items-center mb-30">
                <div className="project-value">
                  <h4 className="mb-2">Global Users</h4>
                  <span className="mb-2">
                    <h4 style={{ color: "#f3ba2f" }}>{globalUsers}</h4>
                  </span>
                </div>
                <div className="project-value-image">
                  <img
                    className="heading-right-image"
                    src="assets/images/MaterialIcons/global_users_flaticon.png"
                    alt="rank"
                    style={{
                      height: 45,
                      width: 45,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Autopool />
      <ToastContainer />
    </>
  );
};

export default Dashboard;
