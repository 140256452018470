import React, {  useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navbar, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Web3 from "web3";
import {
  setConnected,
  setAddress,
  setUserStats,
  setUserInfo,
  setAllUsers,
  setPoolCounts,
  setContractInstance,
  setWeb3,
} from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import config from "../../config.json";
import contractABI from "../../contracts/Pool.json";

const Header = () => {
  const dispatch = useDispatch();
  const { connected, address, contractInstance,userInfo,web3 } = useSelector(
    (state) => state
  );
   const [showDashboard, setShowDashboard] = useState(false);
  const location = useLocation();


  useEffect(() => {
    const detectWeb3Provider = async () => {
      if (window.ethereum) {
        try {
          await window.ethereum.enable(); // Request account access if needed
          const provider = new Web3(window.ethereum);
          dispatch(setWeb3(provider));
          dispatch(setConnected(true));
          const contract = new provider.eth.Contract(
            contractABI,
            config.contractAddress
          );
          dispatch(setContractInstance(contract));
          const accounts = await provider.eth.getAccounts();
          dispatch(setAddress(accounts[0]));

          // Listen for account changes
          window.ethereum.on("accountsChanged", (accounts) => {
            dispatch(setAddress(accounts[0]));
          });
        } catch (error) {
          console.error("Error connecting to Ethereum provider:", error);
          // Handle the error appropriately based on your use case.
        }
      } else if (window.web3) {
        const provider = new Web3(window.web3.currentProvider);
        const contract = new provider.eth.Contract(
          contractABI,
          config.contractAddress
        );
        dispatch(setContractInstance(contract));
        dispatch(setWeb3(provider));
      } else {
        console.error(
          "No web3 provider detected. Please install Metamask or Trust Wallet."
        );
      }
    };

    detectWeb3Provider();
  }, [dispatch]);

   const connectToMetamask = async () => {
     try {
       if (window.ethereum) {
         await window.ethereum.enable(); // Request account access if needed
         
         const provider = new Web3(window.ethereum);
         const accounts = await provider.eth.getAccounts();
         dispatch(setAddress(accounts[0]));
        //  console.log(accounts[0]);
         dispatch(setWeb3(provider));
         dispatch(setConnected(true));
         const contract = new provider.eth.Contract(
           contractABI,
           config.contractAddress
         );
         dispatch(setContractInstance(contract));
         window.ethereum.on("accountsChanged", (accounts) => {
           dispatch(setAddress(accounts[0]));
         });
       }
     } catch (error) {
       console.error("Error connecting to Metamask:", error);
       // Handle the error appropriately based on your use case.
     }
   };

  useEffect(() => {
    const fetchAccountData = async () => {
      if (web3 && contractInstance && address) {
        try {
          const userStat = await contractInstance.methods
            .userStatistics(address)
            .call();
          dispatch(
            setUserStats({
              levelEarning: userStat[0].toString(),
              autopoolReferralEarning: userStat[1].toString(),
              autopoolEarning: userStat[2].toString(),
              directReferralEarning: userStat[3].toString(),
            })
          );

          const allUsers = await contractInstance.methods.getAllUsers().call();
          dispatch(setAllUsers(allUsers));
          const poolCountsArray = [];
          for (let i = 1; i <= 3; i++) {
            let count = await contractInstance.methods.getPoolInfo(i).call();

            poolCountsArray.push(count.toString());
          }
          // console.log(poolCountsArray);
          const [pool1, pool2, pool3] = poolCountsArray;
          dispatch(setPoolCounts({ pool1, pool2, pool3}));
          const userDetails = await contractInstance.methods
            .getUserDetails()
            .call({ from: address });
          // console.log(userDetails);
          const userInfo = {
            referrer: userDetails[0],
            userAddress: userDetails[1],
            referralsCount: userDetails[2].toString(),
            poolIsjoined: userDetails[3],
            poolBoosterIsjoined: userDetails[4],
            poolSuperBoosterIsjoined: userDetails[5],
            poolRecivedPaymentCount: userDetails[6].toString(),
            poolBoosterRecivedPaymentCount: userDetails[7].toString(),
            poolSuperBoosterRecivedPaymentCount: userDetails[8].toString(),
          };
          dispatch(setUserInfo(userInfo));
          
        } catch (error) {
          console.error("Error fetching account data:", error);
          // Handle the error appropriately based on your use case.
        }
      }
    };
    fetchAccountData();
  }, [address, web3, contractInstance, connected, dispatch]);
  

  const shouldHideLink = () => {
    return location.pathname === "/dashboard";
  };

  let marginLeft = 20;
  let buttonSize = "sm"; // Default button size for larger devices
  let marginBottom = 0;

  // For smaller devices, adjust button size and margin
  if (window.innerWidth <= 576) {
    marginLeft = 0;
    buttonSize = "sm"; // Use smaller button size for devices <= 576px width
    marginBottom = 2;
  } else if (shouldHideLink()) {
    marginLeft = 75;
  }

   useEffect(() => {
     if (connected && userInfo && userInfo.userAddress === address) {
       setShowDashboard(true);
     } else {
       setShowDashboard(false);
     }
   }, [connected, userInfo, address]);
  return (
    <div className="container" id="gamfi-header">
      <Navbar
        className="nav bg-transperent"
        expand="lg"
        style={{
          border: "none",
          fontFamily: "Russo One, sans-serif",
        }}
      >
        <Navbar.Brand href="/">
          <img
            className="brand-logo"
            src="assets/bnbxlogo.png"
            alt="Footer-logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          style={{ borderColor: "#ffffff82", padding: "0.10rem 0.30rem" }}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            {!shouldHideLink() ? (
              <>
                <a href="#why_choose_us" style={{ color: "white" }}>
                  Why Choose Us&nbsp;&nbsp;| &nbsp;&nbsp;
                </a>
                <a href="#referral_info" style={{ color: "white" }}>
                  Referral System Info&nbsp;&nbsp;|&nbsp;&nbsp;
                </a>
                <a href="#how_it_works" style={{ color: "white" }}>
                  How it works&nbsp;&nbsp;|&nbsp;&nbsp;
                </a>

                <a href="#pool_info" style={{ color: "white" }}>
                  Pools Info &nbsp;&nbsp;| &nbsp;&nbsp;
                </a>
                {showDashboard ? (
                  <Link
                    className={`mb-${marginBottom}`}
                    to="/dashboard"
                    style={{ color: "white" }}
                  >
                    Dashboard
                  </Link>
                ) : null}
              </>
            ) : (
              <Link
                className={`mb-${marginBottom}`}
                to="/"
                style={{ color: "white", marginTop: 9, marginLeft: 20 }}
              >
                Go To Home
              </Link>
            )}
          </Nav>
          {!connected && (
            <button
              type="button"
              className={`readon white-btn hover-shape ml-auto btn-${buttonSize}`}
              onClick={connectToMetamask}
              style={{
                marginLeft: marginLeft + "%",
                backgroundColor: "#a3ff12",
                position: "relative",
              }}
            >
              <img src="assets/images/icons/connect.png" alt="Icon" /> &nbsp;
              <span className="btn-text">Connect</span>
              <span className="hover-shape1"></span>
              <span className="hover-shape2"></span>
              <span className="hover-shape3"></span>
            </button>
          )}
        </Navbar.Collapse>
      </Navbar>
      <ToastContainer />
    </div>
  );
};

export default Header;
