import React from "react";

const AboutInfo = () => {
  return (
    <>
      <div className="gamfi-about-secion md-pt-80 pt-35 pb-80 md-pb-45">
        <div className="container">
          <div className="banner-slider-inner" id="">
            <div
              className="sc-carousel owl-carousel"
              data-loop="true"
              data-items="1"
              data-margin="70"
              data-autoplay="true"
              data-hoverpause="true"
              data-autoplay-timeout="5000"
              data-smart-speed="1500"
              data-dots="true"
              data-nav="false"
              data-nav-speed="true"
              data-center-mode="false"
              data-mobile-device="1"
              data-mobile-device-nav="false"
              data-mobile-device-dots="true"
              data-ipad-device="1"
              data-ipad-device-nav="false"
              data-ipad-device-dots="true"
              data-ipad-device2="1"
              data-ipad-device-nav2="false"
              data-ipad-device-dots2="false"
              data-md-device="1"
              data-md-device-nav="false"
              data-md-device-dots="true"
            ></div>
          </div>
          <div className="pt-130 md-pt-80 heading-area align-items-center d-flex justify-content-between mb-30">
            <div className="sec-heading" id="why_choose_us">
              <div className="sub-inner mb-15">
                {/* <span className="sub-title">3 easy steps</span> */}
                <img
                  className="heading-left-image"
                  src="assets/images/icons/steps.png"
                  alt="StepsImage"
                />
              </div>
              <h2 className="title">Why Choose Us</h2>
            </div>
          </div>
          <div className="participate-area row mb-4">
            <div className="col-lg-4">
              <div
                className="participate-item d-flex wow fadeInUp"
                data-wow-delay="100ms"
                data-wow-duration="1500ms"
              >
                <div className="number-image">
                  <img
                    src="assets/images/icons/participate-image.png"
                    alt="ParticipateImage"
                  />
                </div>
                <div className="participate-info">
                  <h4 className="mb-10">Decentralized</h4>
                  <p className="description">
                    MATICX it is designed to function autonomously. With a fully
                    automated system, no individuals possess control over the
                    funds, ensuring that your assets are safeguarded solely
                    within the confines of the smart contract.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="participate-item d-flex wow fadeInUp mr-30"
                data-wow-delay="200ms"
                data-wow-duration="2000ms"
              >
                <div className="number-image">
                  <img
                    src="assets/images/icons/participate-image2.png"
                    alt="ParticipateImageOne"
                  />
                </div>
                <div className="participate-info">
                  <h4 className="mb-10">Unbreakable Security</h4>
                  <p className="description">
                    MATICX Smart contract, integral to Blockchain technology,
                    contribute to its robust security, forming an impenetrable
                    barrier against any unauthorized access attempts by hackers.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="participate-item d-flex wow fadeInUp mr-20"
                data-wow-delay="300ms"
                data-wow-duration="2500ms"
              >
                <div className="number-image">
                  <img
                    src="assets/images/icons/participate-image3.png"
                    alt="ParticipateImageTwo"
                  />
                </div>
                <div className="participate-info">
                  <h4 className="mb-10">Transparency</h4>
                  <p className="description">
                    You can transparently view all transactions and details that
                    have been made since the date the Smart Contract was
                    created.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="participate-area row justify-content-center">
            <div className="col-lg-12 col-md-12">
              <div
                className="text-center"
                style={{ background: "transperant" }}
              >
                <h4
                  style={{
                    color: "#a3ff12",
                  }}
                >
                  Fully Automated MATIC Distribution Protocol
                  <img src="assets/hrline.png"></img>
                </h4>
              </div>
              <div className="text-center">
                <h5 className="mb-2">
                MATICX has developed a fully automated "FUND DISTRIBUTION
                  PROTOCOL" to enhance safety for all MATICX users.{" "}
                </h5>
                <h5 className="mb-2">
                  Our smart contract does not store any funds within it.
                </h5>
                <h5 className="mb-2">
                  Instead, all payments are instantly and automatically
                  distributed to users.
                </h5>
                <h5 className="mb-2">
                  When a payment is received, it is directly paid to the user's
                  MetaMask or Trust Wallet account without any delays.
                </h5>
                <h5 className="mb-2">
                  There is no need for admin approval as the entire process is
                  100% automated.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="gamfi-previous-section pb-20 md-pb-10" id="referral_info">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-sm-12">
              <div className="previous-mainmenu">
                <img src="assets/images/referral_info.png" alt="referralInfo" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container" id="how_it_works">
        <div className="sec-inner align-items-center mb-30">
          <div className="sec-heading">
            <div className="sub-inner mb-15">
              {/* <span className="sub-title">FEATURES</span> */}
              <img
                className="heading-left-image"
                src="assets/images/icons/steps.png"
                alt="StepsImageThree"
              />
            </div>
            <h2 className="title">How It Works</h2>
          </div>
          <div className="Key_Points_Conttent">
            <div className="row">
              <div className="col-md-4">
                <div className="Key_Points_Items">
                  {/* <div className="Key_Points_Item_img">
                    <img
                      src="assets/images/icons/Key_icon1.png"
                      alt="icon"
                      className="img-fluid"
                    />
                  </div> */}
                  <div className="Key_Points_Item_Text">
                    {/* <h3>Step 1</h3> */}
                    <h3>Connect to Metamask</h3>
                    <span>
                      If you haven't already, you'll need to install the
                      MetaMask browser extension for your preferred web browser.
                      You can find and install it from the official MetaMask
                      website (https://metamask.io). After that Click on button
                      Connect and connect to maticx.live,Once you approve the
                      connection, your MetaMask wallet will be connected to the
                      maticx.live
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="Key_Points_Items">
                  {/* <div className="Key_Points_Item_img">
                    <img
                      src="assets/images/icons/Key_icon2.png"
                      alt="icon"
                      className="img-fluid"
                    />
                  </div> */}
                 
                </div>
              </div>
              <div className="col-md-4">
                <div className="Key_Points_Items">
                  {/* <div className="Key_Points_Item_img">
                    <img
                      src="assets/images/icons/Key_icon3.png"
                      alt="icon"
                      className="img-fluid"
                    />
                  </div> */}
                  <div className="Key_Points_Item_Text">
                    {/* <h3>Step 3</h3> */}
                    <h3>Joining Pool</h3>
                    <span>
                      When you have connected successfully on maticx.live,You can join any pool, you may be prompted to confirm
                      your transaction or provide additional details. Follow the
                      instructions provided and complete any necessary steps to
                      finalize your participation in the selected pool. After
                      succefully joining pool your pool income starts
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="gamfi-project-section main-project-area pt-10 pb-122 md-pt-25 md-pb-150"
        id="pool_info"
        style={{paddingTop:25,paddingBottom:150}}
      >
        <div className="container">
          <div className="sec-inner align-items-center d-flex justify-content-between">
            <div className="sec-heading">
              <div className="sub-inner mb-15">
                {/* <span className="sub-title">Next Projects</span> */}
                <img
                  className="heading-left-image"
                  src="assets/images/icons/steps.png"
                  alt="StepsImageOne"
                />
              </div>
              <h2 className="title">Pools Info</h2>
            </div>
          </div>
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="project-item hover-shape-border">
                <div className="project-info d-flex">
                  {/* <a href="project-details.html">
                    <img
                      src="assets/images/project/project-image2.png"
                      alt="Project-Image"
                    />
                  </a> */}
                  <div className="project-auother">
                    <h4 className="mb-10">
                      <a href="project-details.html">Autopool</a>
                    </h4>
                    <div className="dsc">100 MATIC</div>
                  </div>
                </div>
                <div className="project-content">
                  <div className="project-header d-flex justify-content-between">
                    <div className="heading-title">
                      <h4>Return 180%</h4>
                    </div>
                    <div className="project-icon">
                      <img
                        src="assets/images/project/project-single-image2.png"
                        alt="ProjectImageTwo"
                      />
                    </div>
                  </div>

                  <div className="project-media">
                    <ul className="project-listing">
                      <li>
                        <i className="fas fa-check-circle text-success"></i> 10%
                        Direct Referral Bonus
                      </li>
                      <li>
                        <i className="fas fa-times text-danger"></i> Referral
                        Not Required
                      </li>
                      <li>You will recieve from autopool</li>
                      <li>
                        <strong>10 MATIC * 18 Payments</strong>
                      </li>
                    </ul>
                  </div>
                </div>
                <span className="border-shadow shadow-1"></span>
                <span className="border-shadow shadow-2"></span>
                <span className="border-shadow shadow-3"></span>
                <span className="border-shadow shadow-4"></span>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="project-item hover-shape-border">
                <div className="project-info d-flex">
                  {/* <a href="project-details.html">
                    <img
                      src="assets/images/project/project-image2.png"
                      alt="Project-Image"
                    />
                  </a> */}
                  <div className="project-auother">
                    <h4 className="mb-10">
                      <a href="project-details.html">Autopool Booster</a>
                    </h4>
                    <div className="dsc">500 MATIC</div>
                  </div>
                </div>
                <div className="project-content">
                  <div className="project-header d-flex justify-content-between">
                    <div className="heading-title">
                      <h4>Return 200%</h4>
                    </div>
                    <div className="project-icon">
                      <img
                        src="assets/images/project/project-single-image2.png"
                        alt="ProjectImageThree"
                      />
                    </div>
                  </div>

                  <div className="project-media">
                    <ul className="project-listing">
                      <li>
                        <i className="fas fa-check-circle text-success"></i> 20%
                        Direct Referral Bonus
                      </li>
                      <li>
                        <i className="fas fa-times text-danger"></i> Referral
                        Not Required
                      </li>
                      <li>You will recieve from autopool</li>
                      <li>
                        <strong>125 MATIC * 8 Payments</strong>
                      </li>
                    </ul>
                  </div>
                </div>
                <span className="border-shadow shadow-1"></span>
                <span className="border-shadow shadow-2"></span>
                <span className="border-shadow shadow-3"></span>
                <span className="border-shadow shadow-4"></span>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="project-item hover-shape-border">
                <div className="project-info d-flex">
                  {/* <a href="project-details.html">
                    <img
                      src="assets/images/project/project-image2.png"
                      alt="Project-Image"
                    />
                  </a> */}
                  <div className="project-auother">
                    <h4 className="mb-10">
                      <a href="project-details.html">Autopool Booster +</a>
                    </h4>
                    <div className="dsc">1000 MATIC</div>
                  </div>
                </div>
                <div className="project-content">
                  <div className="project-header d-flex justify-content-between">
                    <div className="heading-title">
                      <h4>Return 250%</h4>
                    </div>
                    <div className="project-icon">
                      <img
                        src="assets/images/project/project-single-image2.png"
                        alt="ProjectImageFour"
                      />
                    </div>
                  </div>
                  <div className="project-media">
                    <ul className="project-listing">
                      <li>
                        <i className="fas fa-check-circle text-success"></i> 20%
                        Direct Referral Bonus
                      </li>
                      <li>
                        <i className="fas fa-times text-danger"></i> Referral
                        Not Required
                      </li>
                      <li>You will recieve from autopool</li>
                      <li>
                        <strong>250 MATIC * 10 Payments</strong>
                      </li>
                    </ul>
                  </div>
                </div>
                <span className="border-shadow shadow-1"></span>
                <span className="border-shadow shadow-2"></span>
                <span className="border-shadow shadow-3"></span>
                <span className="border-shadow shadow-4"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutInfo;
