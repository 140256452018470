import React from "react";

const Footer = () => {
  return (
    <div className="gamfi-footer-section">
      <div className="footer-area">
        <div className="container">
          <div className="sec-heading text-center">
            <div className="sub-inner mb-52 mb-mb-30">
              <img
                className="heading-right-image"
                src="assets/images/icons/steps2.png"
                alt="Steps-Image2"
              />
              <span className="sub-title white-color">Find us on Social</span>
              <img
                className="heading-left-image"
                src="assets/images/icons/steps.png"
                alt="find-us"
              />
            </div>
          </div>
          <div className="footer-listing text-center mb-100 md-mb-70 xs-mb-50">
            <ul className="footer-icon-list">
              <li>
                <a
                  href="https://twitter.com/BNBX_live"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="icon-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://t.me/bnbx_live"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="icon-telegram"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCZWYuMmrfVuAAwHR3uQF25w"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 60,
                      width: 60,
                      background: "url(assets/images/icons/icon-image.png)",
                    }}
                  >
                    <img
                      src="assets/images/MaterialIcons/youtube.png"
                      style={{
                        height: 25,
                        width: 25,
                        filter: "brightness(0) invert(1)",
                      }}
                      alt="youtube-img"
                    />
                  </div>
                </a>
              </li>
              {/* <li>
                <a href="https://facebook.com">
                  <i className="icon-facebook"></i>
                </a>
              </li> */}
            </ul>
          </div>
          <div className="footer-logo text-center mb-45">
            <img
              src="assets/bnbxlogo.png"
              alt="Footer-logo"
              style={{ height: 65, width: 150 }}
            />
          </div>
          <div className="footer-mainmenu text-center mb-20">
            <div className="row justify-content-center">
              <div className="col-md-6 col-sm-6">
                <button
                  type="button"
                  className={`readon  form-control white-btn hover-shape ml-auto btn-lg`}
                  style={{
                    backgroundColor: "#a3ff12",
                    position: "relative",
                  }}
                >
                  {/* <span className="btn-text"> */}
                  <a
                    target="_blank"
                    href="https://bscscan.com/address/0x3dB222838D9b967573aAD5d50138f5E56c6aFCB0#code"
                    rel="noreferrer"
                    style={{ color: "black" }}
                  >
                    0x3dB222838D9b967573aAD5d50138f5E56c6aFCB0
                  </a>
                  {/* </span> */}
                  <span className="hover-shape1"></span>
                  <span className="hover-shape2"></span>
                  <span className="hover-shape3"></span>
                </button>
              </div>
            </div>
            {/* <ul className="list-unstyled">
              <li>
                <a
                  className="banner-btn wow fadeInUp black-shape-big"
                  target="_blank"
                  data-wow-delay="300ms"
                  data-wow-duration="2500ms"
                  href="https://bscscan.com/address/0xf2021E67f2202828458132E430Bee818be024d0c#code"
                  style={{ color: "black" }}
                  rel="noreferrer"
                >
                  <span className="btn-text">
                    0xf2021E67f2202828458132E430Bee818be024d0c
                  </span>
                  <span className="hover-shape1"></span>
                  <span className="hover-shape2"></span>
                  <span className="hover-shape3"></span>
                </a>
              </li>
            </ul> */}
          </div>

          <div className="copyright-area text-center mb-0">
            <div className="dsc mb-37 md-mb-25">
              Copyright © 2024. All Rights Reserved by MATICX
            </div>
          </div>
          <div className="scrollup text-center">
            <a href="#gamfi-header">
              <i className="icon-arrow_up"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
