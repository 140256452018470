import React,{ useState, useEffect, useCallback } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Web3 from "web3";
// import { ethers } from "ethers";
import { useDispatch, useSelector } from "react-redux";
import AboutInfo from "../pages/AboutInfo";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import {
  setShow,
  setReferralAddress,
  setUserInfo,
  setWeb3,
  setContractInstance,
  setAllUsers,
} from "../../actions";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

// const { contractAddress } = require("../../config.json");
import config from "../../config.json";
const contractABI = require("../../contracts/Pool.json");

const Section = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  let [refByAddress, setRefByAddress] = useState("");
  const navigate = useNavigate();
  const {
    connected,
    address,
    userInfo,
    show,
    referralAddrs,
    contractInstance,
    web3,
  } = useSelector((state) => state);
 const addressRef = React.createRef(); // Reference to the address element

  // console.log(refByAddress);

 const handleCopyClick = () => {
   if (addressRef.current) {
     addressRef.current.select(); // Select the text inside the element
     document.execCommand("copy"); // Copy the selected text to the clipboard
     toast.success("Address copied to clipboard");
   }
 };
  useEffect(() => {
    const detectWeb3 = async () => {
      if (window.ethereum) {
        const provider = new Web3(window.ethereum);
        const contract = new provider.eth.Contract(
          contractABI,
          config.contractAddress
        );
        dispatch(setWeb3(provider));
        dispatch(setContractInstance(contract));
      }
    };
    detectWeb3();
  }, [dispatch]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const uplineAddress = searchParams.get("upline_address");

    if (uplineAddress) {
      dispatch(setReferralAddress(uplineAddress));
    }
  }, [dispatch, location.search]);
  // console.log(referralAddrs);
  if (referralAddrs) {
    refByAddress = referralAddrs;
  } else {
    refByAddress = "0x1972d6BD56813a4727614214BDA9FB7B31ceC4B8";
  }
  
  const addressTocheck = "0x0000000000000000000000000000000000000000";
  // const handleShow = useCallback(() => {
  //   dispatch(setShow(true));
  // }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch(setShow(false));
  }, [dispatch]);

  const addUser = useCallback(async (amount,pool) => {
    try {
      // Ensure the web3 instance and contract are set up
      // console.log(web3, contractInstance);
      if (web3 && contractInstance) {
        const registrationAmount = web3.utils.toWei(amount, "ether"); // Replace with the desired registration amount
        const accounts = await web3.eth.getAccounts();
        const userAddress = accounts[0];
        // console.log(amount);

        // Call the register function from the smart contract
        await contractInstance.methods.joinPool(refByAddress, pool).send({
          from: userAddress,
          value: registrationAmount,
        });
        const allUsers = await contractInstance.methods.getAllUsers().call();
        dispatch(setAllUsers(allUsers));
        dispatch(setShow(false));
        const userDetails = await contractInstance.methods
          .getUserDetails()
          .call({ from: userAddress });
        const userInfo = {
          referrer: userDetails[0],
          userAddress: userDetails[1],
          referralsCount: userDetails[2].toString(),
          poolIsjoined: userDetails[3],
          poolBoosterIsjoined: userDetails[4],
          poolSuperBoosterIsjoined: userDetails[5],
          poolRecivedPaymentCount: userDetails[6].toString(),
          poolBoosterRecivedPaymentCount: userDetails[7].toString(),
          poolSuperBoosterRecivedPaymentCount: userDetails[8].toString(),
        };
        dispatch(setUserInfo(userInfo));
        toast.success("Registration successful!");
        navigate("/dashboard");
      }
    } catch (error) {
      // console.log(error);
      const errorMessage =
        error.data?.message || error?.message || "Something went wrong";
      toast.error(errorMessage);
    }
  }, [dispatch, refByAddress, web3, contractInstance, navigate]);

  const registerBtn =
    (!userInfo && connected) ||
    (userInfo && userInfo.userAddress === addressTocheck) ? (
      <div className="flex flex-col">
        <button
          className="banner-btn black-shape-big mb-2 mr-12"
          data-wow-delay="300ms"
          data-wow-duration="2500ms"
          onClick={() => addUser("0.25",1)}
        >
          <span className="btn-text">Join Autopool With 100 MATIC</span>
          <span className="hover-shape1"></span>
          <span className="hover-shape2"></span>
          <span className="hover-shape3"></span>
        </button>
        <button
          className="banner-btn black-shape-big mb-2 mr-12"
          data-wow-delay="300ms"
          data-wow-duration="2500ms"
          onClick={() => addUser("1.05",2)}
        >
          <span className="btn-text">Join Booster Autopool With 500 MATIC</span>
          <span className="hover-shape1"></span>
          <span className="hover-shape2"></span>
          <span className="hover-shape3"></span>
        </button>
        <button
          className="banner-btn black-shape-big mb-2 mr-12"
          data-wow-delay="300ms"
          data-wow-duration="2500ms"
          onClick={() => addUser("10.05",3)}
        >
          <span className="btn-text">Join Booster+ Autopool With 1000 MATIC</span>
          <span className="hover-shape1"></span>
          <span className="hover-shape2"></span>
          <span className="hover-shape3"></span>
        </button>

        {/* <span style={{ color: "#ffffff" }}>
          If you dont have upline address :
          0x30055fFa29ec529e17bFD2399a8574EF9Afde9bf
        </span> */}
      </div>
    ) : null;
    
  const presentation = (
    <>
      <button
        className="banner-btn wow fadeInUp black-shape-big mb-2"
        data-wow-delay="300ms"
        data-wow-duration="2500ms"
      >
        <span className="btn-text">
          <a href="data/bnbxLive.pdf" style={{ color: "black" }} download>
            Presentation
          </a>
        </span>
        <span className="hover-shape1"></span>
        <span className="hover-shape2"></span>
        <span className="hover-shape3"></span>
      </button>
    </>
  );
  // if (userInfo && userInfo.userAddress !== addressTocheck) {
  //   return <Navigate to="/dashboard" />;
  // }
  return (
    <>
      <div
        id="sc-banner"
        className="sc-banner banner-bg position-relative md-pt-20 md-pb-10"
      >
        <div className="container">
          <div className="banner-content text-center">
            <img
              className="banner-icon wow fadeInUp"
              data-wow-delay="300ms"
              data-wow-duration="2500ms"
              style={{ height: 45, width: 45 }}
              src="assets/bnbx.png"
              alt="bnbx-img"
            />
            <h2
              className="banner-title wow fadeInUp"
              data-wow-delay="300ms"
              data-wow-duration="2500ms"
            >
              Single leg global autopool
            </h2>
            <h3>smart contract</h3>
            <div
              className="description wow fadeInUp"
              data-wow-delay="300ms"
              data-wow-duration="2500ms"
            >
              The MATICX decentralized Single leg global autopool smart contract
              combines advanced digital technology
            </div>
            {registerBtn}
            <br />
            {presentation}
          </div>
        </div>
      </div>
      <AboutInfo />

      <Modal
        show={show}
        onHide={handleClose}
        className="modal fade d-flex align-items-center justify-content-center"
      >
        <Modal.Header
          className="text-center"
          style={{
            backgroundColor: "transparent",
            color: "white",
            borderBottom: "none",
          }}
        >
          <Modal.Title className="modals-title mb-0">REGISTER</Modal.Title>
          <div
            style={{
              color: "#ffffff !important",
              backgroundColor: "transparent",
            }}
          >
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
              aria-label="Close"
            ></button>
          </div>
        </Modal.Header>
        <Modal.Footer></Modal.Footer>
        <Modal.Body className="text-center">
          <Form>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
              <Form.Label>UPLINE ADDRESS</Form.Label>
              <Form.Control
                type="text"
                placeholder="0x..."
                value={refByAddress}
                onChange={(e) => setRefByAddress(e.target.value)}
                autoFocus
                style={{ backgroundColor: "transparent", color: "white" }}
              />
            </Form.Group>
          </Form>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <p style={{ marginBottom: "0px" }}>
                {address ? (
                  <>
                    Your wallet address : {address.slice(0, 5)}*****
                    {address.slice(-5)}
                  </>
                ) : (
                  "Wallet Not Connected"
                )}
              </p>
              <p style={{ color: "#71ffee", marginBottom: "0px" }}>
                If you dont have upline address, Copy below address & paste in
                upline address section & submit:
              </p>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  className="form-control"
                  ref={addressRef}
                  readOnly
                  value="0xdc413818F471688b2397f0628CD5717840e80a98"
                  style={{
                    color: "#71ffee",
                    border: "none",
                    background: "transparent",
                    outline: "none",
                    width: "85%",
                  }}
                />
                <FontAwesomeIcon
                  icon={faCopy}
                  title="Copy"
                  onClick={handleCopyClick}
                  style={{ cursor: "pointer", fontSize: 15, color: "#a3ff12" }}
                />
              </div>
            </div>
          </div>
          <Button
            type="button"
            className="readon white-btn hover-shape ml-auto btn-lg mt-2"
            style={{
              backgroundColor: "#a3ff12",
              color: "black",
            }}
            variant="primary"
            onClick={addUser}
          >
            <span className="btn-text">Submit</span>
            <span className="hover-shape1"></span>
            <span className="hover-shape2"></span>
            <span className="hover-shape3"></span>
          </Button>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
        <ul className="lorem-ul mb-4">
          <li>
            <i className="fas fa-exclamation-triangle text-danger"></i>{" "}
            <span>IMPORTANT!</span> Do not forget about blockchain fee! You
            should have <b>0.1 To 0.001 MATIC </b>or more in your wallet, or your
            transaction will not be completed!{" "}
          </li>
          <li>
            <i className="fas fa-check text-success"></i> Registration fees{" "}
            <span>5 MATIC </span>
          </li>
          <li>
            <i className="fas fa-check text-success"></i> We are working only
            with: <span>MATIC (MATIC Chain) </span> cryptocurrency
          </li>
          <li>
            <i className="fas fa-check text-success"></i> Your registration will
            be success after <span>1 confirmation </span> in blockchain
          </li>
        </ul>
      </Modal>

      <ToastContainer />
    </>
  );
};

export default Section;
