import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { ethers } from "ethers";
import {
  setUserInfo,
  setUserStats,
  setAllUsers,
  setPoolCounts,
} from "../actions";
import { useState, useEffect } from "react";
// import { Navigate } from "react-router-dom";
// const { contractAddress } = require("../config.json");
// const contractABI = require("../contracts/Pool.json");

const Autopool = () => {
  const dispatch = useDispatch();
  const {
    connected,
    address,
    userInfo,
    poolCounts,
    allUsers,
    contractInstance,
    web3,
  } = useSelector((state) => state);
  let [isLoadningOne, setIsLoadingOne] = useState(false);
  let [isLoadningTwo, setIsLoadingTwo] = useState(false);
  let [isLoadningThree, setIsLoadingThree] = useState(false);
  let [isLoadningFour, setIsLoadingFour] = useState(false);
  let [isLoadningFive, setIsLoadingFive] = useState(false);
  const [userSequence, setUserSequence] = useState({});

  // const addressTocheck = "0x0000000000000000000000000000000000000000";
  // if (
  //   (!connected || userInfo && userInfo.userAddress !== addressTocheck) ||
  //   !userInfo
  // ) {
  //   return <Navigate to="/" />;
  // }
  // console.log(userInfo);

  // let labelBg1 = { backgroundColor: "rgb(88, 66, 41)" };
  // let labelBg2 = { backgroundColor: "rgb(88, 66, 41)" };
  // let labelBg3 = { backgroundColor: "rgb(88, 66, 41)" };
  // let labelText1 = "NOT JOINED";
  // let labelText2 = "NOT JOINED";
  // let labelText3 = "NOT JOINED";
  // let labelBg4 = { backgroundColor: "rgb(88, 66, 41)" };
  // let labelText4 = "NOT JOINED";
  // let labelBg5 = { backgroundColor: "rgb(88, 66, 41)" };
  // let labelText5 = "NOT JOINED";

  // if (connected && userInfo) {
  //   if (
  //     userInfo.poolOneIsjoined &&
  //     Number(userInfo.poolOneRecivedPaymentCount) < 16
  //   ) {
  //     labelBg1 = { backgroundColor: "rgb(247, 150, 36)" };
  //     labelText1 = "ACTIVE";
  //   }
  //   if (
  //     userInfo.poolTwoIsjoined &&
  //     Number(userInfo.poolTwoRecivedPaymentCount) < 18
  //   ) {
  //     labelBg2 = { backgroundColor: "rgb(247, 150, 36)" };
  //     labelText2 = "ACTIVE";
  //   }
  //   if (
  //     userInfo.poolThreeIsjoined &&
  //     Number(userInfo.poolThreeRecivedPaymentCount) < 20
  //   ) {
  //     labelBg3 = { backgroundColor: "rgb(247, 150, 36)" };
  //     labelText3 = "ACTIVE";
  //   }

  //   if (
  //     userInfo.poolBoosterIsjoined &&
  //     Number(userInfo.poolBoosterRecivedPaymentCount) < 8
  //   ) {
  //     labelBg4 = { backgroundColor: "rgb(247, 150, 36)" };
  //     labelText4 = "ACTIVE";
  //   }

  //   if (
  //     userInfo.poolSuperBoosterIsjoined &&
  //     Number(userInfo.poolSuperBoosterRecivedPaymentCount) < 10
  //   ) {
  //     labelBg5 = { backgroundColor: "rgb(247, 150, 36)" };
  //     labelText5 = "ACTIVE";
  //   }

  //   if (Number(userInfo.poolOneRecivedPaymentCount) >= 16) {
  //     labelBg1 = { backgroundColor: "rgb(27, 206, 101)" };
  //     labelText1 = "COMPLETED";
  //   }
  //   if (Number(userInfo.poolTwoRecivedPaymentCount) >= 18) {
  //     labelBg2 = { backgroundColor: "rgb(27, 206, 101)" };
  //     labelText2 = "COMPLETED";
  //   }
  //   if (Number(userInfo.poolThreeRecivedPaymentCount) >= 20) {
  //     labelBg3 = { backgroundColor: "rgb(27, 206, 101)" };
  //     labelText3 = "COMPLETED";
  //   }
  //   if (Number(userInfo.poolBoosterRecivedPaymentCount) >= 20) {
  //     labelBg4 = { backgroundColor: "rgb(27, 206, 101)" };
  //     labelText4 = "COMPLETED";
  //   }
  //   if (Number(userInfo.poolSuperBoosterRecivedPaymentCount) >= 20) {
  //     labelBg5 = { backgroundColor: "rgb(27, 206, 101)" };
  //     labelText5 = "COMPLETED";
  //   }
  // }

  const joinPool = async (poolNo, amount) => {
    
    if (!connected) {
      toast.error("Please connect your wallet");
      return;
    }

    if (poolNo === 1) {
      setIsLoadingOne(true);
    }
    if (poolNo === 2) {
      setIsLoadingTwo(true);
    }
    if (poolNo === 3) {
      setIsLoadingThree(true);
    }
    // if (poolNo === 4) {
    //   setIsLoadingFour(true);
    // }
    // if (poolNo === 5) {
    //   setIsLoadingFive(true);
    // }

    try {
      const accounts = await web3.eth.getAccounts();
      const contract = contractInstance;
      const refByAddress = "0x1972d6BD56813a4727614214BDA9FB7B31ceC4B8";

      await contract.methods.joinPool(refByAddress,poolNo).send({
        from: accounts[0],
        value: web3.utils.toWei(amount, "ether"),
      });

      if (poolNo === 2) {
        toast.success("Autopool Booster is joined successfully!");
        setIsLoadingFour(false);
      } else if (poolNo === 3) {
        toast.success("Autopool Booster+ is joined successfully!");
        setIsLoadingFive(false);
      } else {
        toast.success(`Autopool is joined successfully!`);
        setIsLoadingOne(false);
        setIsLoadingTwo(false);
        setIsLoadingThree(false);
        setIsLoadingFour(false);
        setIsLoadingFive(false);
      }
      if (web3 && contractInstance && address) {
        try {
          const userStat = await contractInstance.methods
            .userStatistics(address)
            .call();
          dispatch(
            setUserStats({
              levelEarning: userStat[0].toString(),
              autopoolReferralEarning: userStat[1].toString(),
              autopoolEarning: userStat[2].toString(),
              directReferralEarning: userStat[3].toString(),
            })
          );

          const allUsers = await contractInstance.methods.getAllUsers().call();
          dispatch(setAllUsers(allUsers));
          const poolCountsArray = [];
          for (let i = 1; i <= 3; i++) {
            let count = await contractInstance.methods.getPoolInfo(i).call();
            poolCountsArray.push(count.toString());
          }
          // console.log(poolCountsArray);
          const [pool1, pool2, pool3] = poolCountsArray;
          dispatch(setPoolCounts({ pool1, pool2, pool3}));
          const userDetails = await contractInstance.methods
            .getUserDetails()
            .call({ from: address });
          const userInfo = {
            referrer: userDetails[0],
            userAddress: userDetails[1],
            referralsCount: userDetails[2].toString(),
            poolIsjoined: userDetails[3],
            poolBoosterIsjoined: userDetails[4],
            poolSuperBoosterIsjoined: userDetails[5],
            poolRecivedPaymentCount: userDetails[6].toString(),
            poolBoosterRecivedPaymentCount: userDetails[7].toString(),
            poolSuperBoosterRecivedPaymentCount: userDetails[8].toString(),
          };
          const result = await userSequenceNumber(address);
          setUserSequence(result);
          dispatch(setUserInfo(userInfo));
        } catch (error) {
          console.error("Error fetching account data:", error);
          // Handle the error appropriately based on your use case.
        }
      }
    } catch (error) {
      setIsLoadingOne(false);
      setIsLoadingTwo(false);
      setIsLoadingThree(false);
      setIsLoadingFour(false);
      setIsLoadingFive(false);
      const errorMessage = error.message || "Something went wrong";
      toast.error(errorMessage);
    }
  };

  let allUserGlobal = allUsers;
  let pendingLastUserpayCountPoolOne = 0;
  // let pendingLastUserpayCountPoolTwo = 0;
  // let pendingLastUserpayCountPoolThree = 0;
  let pendingLastUserpayCountPoolBooster = 0;
  let pendingLastUserpayCountPoolSuperBooster = 0;
  let pendingPoolOneCount = [];
  // let pendingPoolTwoCount = [];
  // let pendingPoolThreeCount = [];
  let pendingPoolBoosterCount = [];
  let pendingPoolSuperBoosterCount = [];
  const lastUserSequenceNumber = [0, 0, 0, 0, 0];
  const paymentcounts = [0, 0, 0, 0, 0];
  let totalRefAmt = 0;
  if (allUserGlobal) {
     const filteredallUserGlobalPoolOne = allUsers.filter(
       (obj) => obj.poolIsjoined
     );

     const filteredallUserGlobalPoolBooster = allUsers.filter(
       (obj) => obj.poolBoosterIsjoined
     );
     const filteredallUserGlobalPoolSuperBooster = allUsers.filter(
       (obj) => obj.poolSuperBoosterIsjoined
     );
    // const completedPoolOneCount = filteredallUserGlobalPoolOne.filter(
    //   (obj) => parseInt(obj.poolOneRecivedPaymentCount, 10) >= 16
    // );
    // const completedPoolTwoCount = filteredallUserGlobalPoolTwo.filter(
    //   (obj) => parseInt(obj.poolOneRecivedPaymentCount, 10) >= 18
    // );
    // const completedPoolThreeCount = filteredallUserGlobalPoolThree.filter(
    //   (obj) => parseInt(obj.poolOneRecivedPaymentCount, 10) >= 20
    // );
    // const completedPoolBoosterCount = filteredallUserGlobalPoolBooster.filter(
    //   (obj) => parseInt(obj.poolOneRecivedPaymentCount, 10) >= 8
    // );
    // const completedPoolSuperBoosterCount =
    //   filteredallUserGlobalPoolSuperBooster.filter(
    //     (obj) => parseInt(obj.poolOneRecivedPaymentCount, 10) >= 10
    //   );
    pendingPoolOneCount = filteredallUserGlobalPoolOne.map((obj, index) => ({
      ...obj,
      index: index + 1,
    }));
    pendingPoolBoosterCount = filteredallUserGlobalPoolBooster.map(
      (obj, index) => ({ ...obj, index: index + 1 })
    );
    pendingPoolSuperBoosterCount = filteredallUserGlobalPoolSuperBooster.map(
      (obj, index) => ({ ...obj, index: index + 1 })
    );

    // Pending
    pendingPoolOneCount = filteredallUserGlobalPoolOne.filter(
      (obj) =>
        parseInt(obj.poolRecivedPaymentCount, 10) >= 1 &&
        parseInt(obj.poolRecivedPaymentCount, 10) < 16
    );
    pendingPoolBoosterCount = filteredallUserGlobalPoolBooster.filter(
      (obj) =>
        parseInt(obj.poolBoosterRecivedPaymentCount, 10) >= 1 &&
        parseInt(obj.poolBoosterRecivedPaymentCount, 10) < 8
    );
    // console.log(pendingPoolBoosterCount);
    pendingPoolSuperBoosterCount = filteredallUserGlobalPoolSuperBooster.filter(
      (obj) =>
        parseInt(obj.poolSuperBoosterRecivedPaymentCount, 10) >= 1 &&
        parseInt(obj.poolSuperBoosterRecivedPaymentCount, 10) < 10
    );

    if (pendingPoolOneCount.length) {
      pendingLastUserpayCountPoolOne =
        pendingPoolOneCount[
          pendingPoolOneCount.length - 1
        ].poolRecivedPaymentCount.toString();
      lastUserSequenceNumber[0] =
        pendingPoolOneCount[pendingPoolOneCount.length - 1].index;
      
    }
    // if (pendingPoolTwoCount.length) {
    //   pendingLastUserpayCountPoolTwo =
    //     pendingPoolTwoCount[
    //       pendingPoolTwoCount.length - 1
    //     ].poolTwoRecivedPaymentCount.toString();
    //   lastUserSequenceNumber[1] =
    //     pendingPoolTwoCount[pendingPoolTwoCount.length - 1].index;
     
    // }
    // if (pendingPoolThreeCount.length) {
    //   pendingLastUserpayCountPoolThree =
    //     pendingPoolThreeCount[
    //       pendingPoolThreeCount.length - 1
    //     ].poolThreeRecivedPaymentCount.toString();
    //   lastUserSequenceNumber[2] =
    //     pendingPoolThreeCount[pendingPoolThreeCount.length - 1].index;
      
    // }
    if (pendingPoolBoosterCount.length) {
      pendingLastUserpayCountPoolBooster =
        pendingPoolBoosterCount[
          pendingPoolBoosterCount.length - 1
        ].poolBoosterRecivedPaymentCount.toString();
      lastUserSequenceNumber[3] =
        pendingPoolBoosterCount[pendingPoolBoosterCount.length - 1].index;
      
    }
    if (pendingPoolSuperBoosterCount.length) {
      pendingLastUserpayCountPoolSuperBooster =
        pendingPoolSuperBoosterCount[
          pendingPoolSuperBoosterCount.length - 1
        ].poolSuperBoosterRecivedPaymentCount.toString();
      lastUserSequenceNumber[4] =
        pendingPoolSuperBoosterCount[
          pendingPoolSuperBoosterCount.length - 1
        ].index;
      
    }
    filteredallUserGlobalPoolOne.forEach((element) => {
      let recieveNoofPay = element.poolRecivedPaymentCount.toString();
      paymentcounts[0] += Number(recieveNoofPay);
    });
    //  filteredallUserGlobalPoolTwo.forEach((element) => {
    //    let recieveNoofPay = element.poolTwoRecivedPaymentCount.toString();
    //    paymentcounts[1] += Number(recieveNoofPay);
    //  });
    // filteredallUserGlobalPoolThree.forEach((element) => {
    //   let recieveNoofPay = element.poolThreeRecivedPaymentCount.toString();
    //   paymentcounts[2] += Number(recieveNoofPay);
    // });
    filteredallUserGlobalPoolBooster.forEach((element) => {
      let recieveNoofPay = element.poolBoosterRecivedPaymentCount.toString();
      paymentcounts[3] += Number(recieveNoofPay);
    });
    filteredallUserGlobalPoolSuperBooster.forEach((element) => {
      let recieveNoofPay =
        element.poolSuperBoosterRecivedPaymentCount.toString();
      paymentcounts[4] += Number(recieveNoofPay);
    });

    totalRefAmt =
      (filteredallUserGlobalPoolOne.length - 1) * 0.02 +
      // (filteredallUserGlobalPoolTwo.length - 1) * 0.02 +
      // (filteredallUserGlobalPoolThree.length - 1) * 0.02 +
      (filteredallUserGlobalPoolBooster.length - 1) * 0.2 +
      (filteredallUserGlobalPoolSuperBooster.length - 1) * 2;
    
  }

  async function userSequenceNumber(address) {
    if (address) {
      let sequenceNumber = await contractInstance.methods
        .getUserSequenceNumber(address)
        .call();
      return {
        poolOne: sequenceNumber.autopool.toString(),
        poolTwo: sequenceNumber.autopoolBooster.toString(),
        poolThree: sequenceNumber.autopoolSuperBooster.toString(),
      };
    } else {
      return { poolOne: 0, poolTwo: 0, poolThree: 0};
    }
  }
  useEffect(() => {
    async function fetchData() {
      try {
        // Call the function to fetch pool details
        const result = await userSequenceNumber(address);
        setUserSequence(result);
      } catch (error) {
        return {
          poolOne: 0,
          poolTwo: 0,
          poolThree: 0
        };
      }
    }

    if (address) {
      fetchData();
    }
  }, [address]);



  const lodingBtn = (
    <h6 className="text text-warning">Confirming trasaction...</h6>
  );
  let totalUsers = 0;
  if (poolCounts) {
    totalUsers =
      Number(poolCounts.pool1) +
      Number(poolCounts.pool2) +
      Number(poolCounts.pool3) 
  }
  // console.log(poolCounts);


  return (
    <>
      <div className="gamfi_TierSect">
        <div
          className="container"
          style={{ fontFamily: "Russo One, sans-serif", maxWidth: "1000px" }}
        >
          <div className="row justify-content-center">
            <div className="col-md-4">
              <div className="gamfi_Tier_card">
                <h3>Autopool</h3>
                <h3>100 MATIC</h3>
                <button className="btn my-button">Return 180%</button>
                <span>
                  Direct Referral Bonus:{" "}
                  <strong
                    style={{
                      color: "#ffffff",
                    }}
                  >
                    10%
                  </strong>
                </span>
                <p>Referral Not Necessary</p>
                <p>You will recieve from autopool</p>
                <h4 className="mb-2">10 MATIC * 18 Payments</h4>
                <button className="btn mb-2 my-button">180 MATIC</button>
                {connected && userInfo && userInfo.poolIsjoined ? (
                  <div>
                    <h4
                      title={`${pendingLastUserpayCountPoolOne} out of 20 payments received`}
                    >
                      Current Paying No : {pendingPoolOneCount.length}
                      {" - "}
                      {pendingLastUserpayCountPoolOne}/18
                    </h4>
                    <h4>Your Sequence No : {userSequence.poolOne}</h4>

                    <button
                      className="btn rc-button"
                      style={{ backgroundColor: "#a3ff12", fontSize: "0.9rem" }}
                    >
                      <span className="btn-text">
                        Received no of payments:{" "}
                        {userInfo.poolRecivedPaymentCount}
                      </span>
                    </button>
                  </div>
                ) : connected && userInfo && !userInfo.poolIsjoined ? (
                  <>
                    <h4>
                      Current Paying No: {pendingLastUserpayCountPoolOne.length}
                      {" - "}
                      {pendingLastUserpayCountPoolOne}/18
                    </h4>
                    <h6>You have not entered in this pool</h6>
                    {isLoadningThree ? (
                      lodingBtn
                    ) : (
                      <button
                        onClick={() => joinPool(1, "0.2")}
                        className="readon white-btn hover-shape ml-auto btn-lg"
                        style={{
                          backgroundColor: "#a3ff12",
                          padding: ".4rem 0.8rem",
                        }}
                      >
                        <span className="btn-text">Enter</span>
                        <span className="hover-shape1"></span>
                        <span className="hover-shape2"></span>
                        <span className="hover-shape3"></span>
                      </button>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="gamfi_Tier_card">
                {/* <div className="corner-text-label"> */}
                {/* <div className="label-text" style={labelBg4}>
                    {labelText4}
                  </div> */}
                {/* </div> */}
                <h3>Autopool Booster</h3>
                <h3>500 MATIC</h3>
                <button className="btn my-button-booster">Return 200%</button>
                <span>
                  Direct Referral Bonus:{" "}
                  <strong
                    style={{
                      color: "#ffffff",
                    }}
                  >
                    20%
                  </strong>
                </span>
                <p>Referral Not Necessary</p>
                <p>You will recieve from autopool</p>
                <h4 className="mb-2">125 MATIC * 8 Payments</h4>
                <button className="btn mb-2 my-button-booster">1000 MATIC</button>
                {connected && userInfo && userInfo.poolBoosterIsjoined ? (
                  <div>
                    <h4
                      title={`${pendingLastUserpayCountPoolBooster} out of 8 payments received`}
                    >
                      Current Paying No : {lastUserSequenceNumber[3]}
                      {" - "}
                      {pendingLastUserpayCountPoolBooster}/8
                    </h4>
                    <h4>Your Sequence No : {userSequence.poolTwo}</h4>

                    <button
                      className="btn rc-button"
                      style={{ backgroundColor: "#a3ff12", fontSize: "0.9rem" }}
                    >
                      <span className="btn-text">
                        Received no of payments:{" "}
                        {userInfo.poolBoosterRecivedPaymentCount}
                      </span>
                    </button>
                  </div>
                ) : connected && userInfo && !userInfo.poolBoosterIsjoined ? (
                  <>
                    <h4>
                      Current Paying No : {pendingPoolBoosterCount.length}
                      {" - "}
                      {pendingLastUserpayCountPoolBooster}/8
                    </h4>
                    <h6>You have not entered in this pool</h6>
                    {isLoadningFour ? (
                      lodingBtn
                    ) : (
                      <button
                        onClick={() => joinPool(2, "1")}
                        className="readon white-btn hover-shape ml-auto btn-lg"
                        style={{
                          backgroundColor: "#a3ff12",
                          padding: ".4rem 0.8rem",
                        }}
                      >
                        <span className="btn-text">Enter</span>
                        <span className="hover-shape1"></span>
                        <span className="hover-shape2"></span>
                        <span className="hover-shape3"></span>
                      </button>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="gamfi_Tier_card">
                {/* <div className="corner-text-label"> */}
                {/* <div className="label-text" style={labelBg5}>
                    {labelText5}
                  </div> */}
                {/* </div> */}
                <h3>Autopool Booster +</h3>
                <h3>1000 MATIC</h3>
                <button className="btn my-button-booster">Return 250%</button>
                <span>
                  Direct Referral Bonus:{" "}
                  <strong
                    style={{
                      color: "#ffffff",
                    }}
                  >
                    20%
                  </strong>
                </span>
                <p>Referral Not Necessary</p>
                <p>You will recieve from autopool</p>
                <h4 className="mb-2">250 MATIC * 10 Payments</h4>
                <button className="btn mb-2 my-button-booster">
                  <b>2500 MATIC</b>
                </button>
                {connected && userInfo && userInfo.poolSuperBoosterIsjoined ? (
                  <div>
                    <h4
                      title={`${pendingLastUserpayCountPoolSuperBooster} out of 10 payments received`}
                    >
                      Current Paying No : {pendingPoolSuperBoosterCount.length}
                      {" - "}
                      {pendingLastUserpayCountPoolSuperBooster}/10
                    </h4>
                    <h4>Your Sequence No : {userSequence.poolThree}</h4>
                    <button
                      className="btn rc-button"
                      style={{ backgroundColor: "#a3ff12", fontSize: "0.9rem" }}
                    >
                      <span className="btn-text">
                        Received no of payments:{" "}
                        {userInfo.poolSuperBoosterRecivedPaymentCount}
                      </span>
                    </button>
                  </div>
                ) : connected &&
                  userInfo &&
                  !userInfo.poolSuperBoosterIsjoined ? (
                  <>
                    <h4>
                      Current Paying No : {pendingPoolSuperBoosterCount.length}
                      {" - "}
                      {pendingLastUserpayCountPoolSuperBooster}/10
                    </h4>
                    <h6>You have not entered in this pool</h6>
                    {isLoadningFive ? (
                      lodingBtn
                    ) : (
                      <button
                        onClick={() => joinPool(3, "10")}
                        className="readon white-btn hover-shape ml-auto btn-lg"
                        style={{
                          backgroundColor: "#a3ff12",
                          padding: ".4rem 0.8rem",
                        }}
                      >
                        <span className="btn-text">Enter</span>
                        <span className="hover-shape1"></span>
                        <span className="hover-shape2"></span>
                        <span className="hover-shape3"></span>
                      </button>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="Market_CapSect">
          <div className="container">
            <div
              className="Market_CapAllContent"
              style={{ marginBottom: "30px" }}
            >
              <div style={{ padding: 10 }}>
                <div className="scrolling-text">
                  {poolCounts.length <= 0 ? (
                    <div
                      style={{
                        color: "#ffffff",
                        fontFamily: "Russo One, sans-serif",
                      }}
                    >
                      No pool counts available.
                    </div> // Show a message when poolCounts is empty
                  ) : (
                    <>
                      {" "}
                      <div className="parent-div">
                        <img
                          style={{ height: 70, width: 70 }}
                          src="assets/images/slider/allusers.png"
                          alt="autopool-slider-1"
                        ></img>
                        <h4 className="slider_h">
                          All Autopool Users
                          <br />{" "}
                          <span style={{ color: "#a3ff12" }}>
                            {totalUsers} /{" "}
                            {(
                              poolCounts.pool1 * 0.2 +
                              poolCounts.pool2 * 1 +
                              poolCounts.pool3 * 10
                            ).toFixed(1)}{" "}
                            MATIC
                          </span>
                        </h4>
                        <img
                          className="slider_images"
                          src="assets/images/slider/sliderpool1.png"
                          alt="autopool-slider-1"
                        ></img>
                        <h4 className="slider_h">
                          Autopool Users
                          <br />{" "}
                          <span style={{ color: "#a3ff12" }}>
                            {poolCounts.pool1} /{" "}
                            {(poolCounts.pool1 * 0.2).toFixed(1)} MATIC
                          </span>
                        </h4>
                        <img
                          className="slider_images"
                          src="assets/images/slider/sliderpool2.png"
                          alt="autopool-slider-1"
                        ></img>
                        <h4 className="slider_h">
                          Autopool Booster Users
                          <br />{" "}
                          <span style={{ color: "#a3ff12" }}>
                            {poolCounts.pool2} /{" "}
                            {(poolCounts.pool2 * 1).toFixed(1)} MATIC
                          </span>
                        </h4>
                        <img
                          className="slider_images"
                          src="assets/images/slider/sliderpool3.png"
                          alt="autopool-slider-1"
                        ></img>
                        <h4 className="slider_h">
                          Autopool Booster+ Users
                          <br />{" "}
                          <span style={{ color: "#a3ff12" }}>
                            {poolCounts.pool3} /{" "}
                            {(poolCounts.pool3 * 10).toFixed(1)} MATIC
                          </span>
                        </h4>
                        {/* <img
                          className="slider_images"
                          src="assets/images/slider/sliderpool4.png"
                          alt="autopool-slider-1"
                        ></img>
                        <h4 className="slider_h">
                          Autopool Booster Users
                          <br />{" "}
                          <span style={{ color: "#a3ff12" }}>
                            {poolCounts.pool4} /{" "}
                            {(poolCounts.pool4 * 1).toFixed(1)} BNB
                          </span>
                        </h4>
                        <img
                          className="slider_images"
                          src="assets/images/slider/sliderpool5.png"
                          alt="autopool-slider-1"
                        ></img>
                        <h4 className="slider_h">
                          Autopool Booster+ Users
                          <br />{" "}
                          <span style={{ color: "#a3ff12" }}>
                            {poolCounts.pool5} /{" "}
                            {(poolCounts.pool5 * 10).toFixed(1)} BNB
                          </span>
                        </h4> */}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <span className="redBordergradient"></span>
          <span className="greenBordergradient"></span>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Autopool;
