export const setConnected = (connected) => ({
  type: "SET_CONNECTED",
  payload: connected,
});

export const setAddress = (address) => ({
  type: "SET_ADDRESS",
  payload: address,
});

export const setUserInfo = (userInfo) => ({
  type: "SET_USER_INFO",
  payload: userInfo,
});

export const setUserStats = (userStats) => ({
  type: "SET_USER_STATS",
  payload: userStats,
});

export const setShow = (show) => ({
  type: "SET_SHOW",
  payload: show,
});

export const setRefByAddress = (refByAddress) => ({
  type: "SET_REF_ADDRESS",
  payload: refByAddress,
});
export const setSigner = (signers) => ({
  type: "SET_SIGNER",
  payload: signers,
});

export const setAllUsers = (allUsers) => ({
  type: "SET_ALL_USERS",
  payload: allUsers,
});
export const setPoolCounts = (poolCounts) => ({
  type: "SET_POOL_COUNTS",
  payload: poolCounts,
});
export const setReferralAddress = (referralAddrs) => ({
  type: "SET_REFERRAL_ADDRESS",
  payload: referralAddrs,
});
export const setContractInstance = (contractInstance) => ({
  type: "SET_CONTRACT_INSTANCE",
  payload: contractInstance,
});
export const setWeb3 = (web3) => ({
  type: "SET_WEB3",
  payload: web3,
});